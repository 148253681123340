<template>
    <teleport to="head">
        <title>{{ txt[prefs.lang].confirmation }} – PLANTA</title>
    </teleport>

    <h1>{{ txt[prefs.lang].confirmation }}</h1>

    <div class="centered narrow distanced" style="max-width: 700px;">

        <template v-if="confirmation">

            <p>{{ txt[prefs.lang].confirmed }}</p>
            <p>{{ txt[prefs.lang].signed_up }}</p>
            <p style="margin: 30px;">🌿</p>

        </template>

        <template v-else>
            <p v-if="error">{{ txt[prefs.lang][error] }}</p>
            <p v-else>CHECKING...</p>
            <p style="margin: 30px;">🌿</p>
        </template>
    </div>

</template>

<script>
import axios from "axios"
import { txt } from '@/use/txt'
import { computed, inject, reactive, ref } from "vue"
import router from '@/router/index'
import useCart from "@/use/cart"

export default {
    setup(props, context) {

        // console.log(router.currentRoute.value.query.email)
        const baseUrl = inject('baseUrl')
        const { prefs, message, me } = useCart()

        const confirmation = ref(false)
        const error = ref(false)

        let err = { 403: "confirmation_failed", 404: "confirmation_failed", 409: "mail_exists", 422: "confirmation_failed", 429: "too_many" }

        async function confirm() {
            await axios.get('/sanctum/csrf-cookie')
            await axios.post('/confirm', {
                email: router.currentRoute.value.query.email,
                token: router.currentRoute.value.query.token
            }).then(r => {
                // console.log('R: '+r)
                confirmation.value = true
                context.emit('getMe')
                router.push({ path: '/profile' })
            }).catch(e => {
                error.value = err[e.response.status] ? err[e.response.status] : 'wrong'
                message(txt[prefs.lang].error, txt[prefs.lang][error.value], 'warning')
                confirmation.value = false
                // console.log('E: '+e)
                axios.post('/api/error', { creds: router.currentRoute.value.query.email, type: 5, error: e.response.status })
            })
        }
        confirm()

        return { prefs, me, txt, confirmation, error }
    }
}
</script>
